/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Snippets</h3>
            <vue-draggable-container id="wordBank" class="list wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer command-block">tar</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">until</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">zip</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">egrep</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">awk</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">$log</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"$6"</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">pgrep</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">repeat</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">/tmp/tempfile</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">locate</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">filename</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">rar</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">then</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"log.[1-6]$"</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">in</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">done</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">/var/log</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">for</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">xz</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"$1"</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">sed</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">gzip</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">"$log.[1-6]$"</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">while</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row mt-base">
                <h1>#!/bin/bash</h1>
              </div>
              <div class="vx-row mt-base">
                <h1>#name: script.sh</h1>
              </div>
              <div class="vx-row mt-base flex items-center">
                <h1 class="mr-8">find /var/log -type f -maxdepth 1 | grep</h1>
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg"
                  id="input0"
                  style="width: 10vw"
                ></vue-draggable-container>
                <h1 class="ml-8">> /tmp/tempfile</h1>
                <div class="mt-8 flex items-center">
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input1"
                    style="width: 10vw"
                  ></vue-draggable-container>
                  <h1 class="ml-8">filename</h1>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                    id="input2"
                    style="width: 10vw"
                  ></vue-draggable-container>
                  <h1 class="ml-8">$(cat</h1>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg ml-8"
                    id="input3"
                    style="width: 10vw"
                  ></vue-draggable-container>
                  <h1 class="ml-8">)</h1>
                </div>
              </div>
              <div class="vx-row mt-base">
                <h1>do</h1>
              </div>
              <div class="vx-row mt-base">
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg"
                  id="input4"
                  style="width: 10vw"
                ></vue-draggable-container>
                <h1 class="ml-8">$filename</h1>
              </div>
              <div class="vx-row mt-base">
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg"
                  id="input5"
                  style="width: 10vw"
                ></vue-draggable-container>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-command';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === '"$log.[1-6]$"') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#77FAAE';
        document.getElementById('input0').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input1').textContent === 'for') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#77FAAE';
        document.getElementById('input1').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input2').textContent === 'in') {
        totalScore++;
        document.getElementById('input2').style.borderColor = '#77FAAE';
        document.getElementById('input2').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input3').textContent === '/tmp/tempfile') {
        totalScore++;
        document.getElementById('input3').style.borderColor = '#77FAAE';
        document.getElementById('input3').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input4').textContent === 'gzip') {
        totalScore++;
        document.getElementById('input4').style.borderColor = '#77FAAE';
        document.getElementById('input4').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input5').textContent === 'done') {
        totalScore++;
        document.getElementById('input5').style.borderColor = '#77FAAE';
        document.getElementById('input5').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.currently-dragging-standard-command {
  background-color: #0042ff !important;
  padding: 12px;
  color: white;
  z-index: 99;
  min-width: auto;
}

.command-block {
  display: inline-flex;
  max-height: 100px;
  margin: 5px;
  padding: 10px;
  text-align: left;
  color: #fff;
  border: 1px solid #3f3f3f;
  background-color: #fd4445;
  border-radius: 5px;
}
</style>
